import { render, staticRenderFns } from "./StatusColumn.vue?vue&type=template&id=6f3f8a64&scoped=true"
import script from "./StatusColumn.vue?vue&type=script&lang=js"
export * from "./StatusColumn.vue?vue&type=script&lang=js"
import style0 from "./StatusColumn.vue?vue&type=style&index=0&id=6f3f8a64&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f3f8a64",
  null
  
)

export default component.exports